<template>
  <v-container class="login-container" fluid="fluid" fill-height="fill-height">
    <v-layout
      class="login-container"
      justify-center="justify-center"
      align-center="align-center"
    >
      <v-flex xs12="xs12" sm8="sm8" md4="md4">
        <v-card
          class="mx-auto px-1 elevation-4"
          :disabled="loading"
          :loading="loading"
          max-width="400"
          min-height="250"
        >
          <v-card-title class="font-weight-light">{{
            recaptchaSolved ? "Confirmar SMS" : "Entrar com telefone"
          }}</v-card-title>
          <v-card-text>
            <v-form
              v-if="!recaptchaSolved"
              ref="form"
              v-model="valid"
              lazy-validation="lazy-validation"
            >
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    label="País"
                    prefix="+"
                    ref="country"
                    type="tel"
                    v-mask="'###'"
                    v-model="country"
                  ></v-text-field>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    :rules="phoneRules"
                    @keypress.enter="onSubmit()"
                    hint="Você irá receber um SMS para verificação. Esse serviço poderá ser cobrado pela sua operadora."
                    label="Informe seu telefone"
                    persistent-hint="persistent-hint"
                    placeholder="(00) 00000-0000"
                    ref="phone"
                    type="tel"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    v-model="phone"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-text-field
              class="subtitle-1"
              :error-messages="smsCodeError"
              @focus="smsCodeError = null"
              @keypress.enter="onValidate()"
              label="Informe o código recebido"
              ref="smsCode"
              type="tel"
              v-else
              v-mask="'###-###'"
              v-model="smsCode"
            ></v-text-field>
          </v-card-text>
          <v-layout
            id="recaptcha-container"
            justify-center="justify-center"
            align-center="align-center"
          ></v-layout>
          <v-card-actions class="card-btn-bottom-start" v-if="recaptchaSolved">
            <v-btn
              :disabled="codeTimeout != 0"
              @click="resendCode"
              color="accent"
              text="text"
              small="small"
              ><span
                >Reenviar código
                {{ codeTimeout > 0 ? `(${codeTimeout})` : "" }}</span
              ></v-btn
            >
          </v-card-actions>
          <v-card-actions class="card-btn-bottom">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid || !sendBtn"
              @click="recaptchaSolved ? onValidate() : onSubmit()"
              color="accent"
              >{{ recaptchaSolved ? "Validar" : "Enviar" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import firebase, { auth, getMessageError } from "../../plugins/google/firebase";

export default {
  name: "phone-auth",
  directives: { mask },
  data: () => ({
    loading: false,
    phone: null,
    country: "55",
    smsCode: null,
    smsCodeError: null,
    phoneRules: [
      (v) => !!v || "Telefone inválido",
      (v) => (v && v.length > 13) || "Telefone inválido",
    ],
    recaptchaSolved: false,
    valid: false,
    sendBtn: true,

    codeTimeout: -1,
  }),
  mounted() {
    setTimeout(() => this.$refs.phone.focus(), 250);
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: () => {
          this.recaptchaSolved = true;
          this.sendBtn = true;
        },
      },
    );
  },
  beforeDestroy() {
    document.getElementById("recaptcha-container")?.remove();
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.signInWithPhoneNumber();
    },

    onValidate() {
      this.codeTimeout = -1;
      clearInterval(this.intervalID);

      if (!this.smsCode || this.smsCode.length < 4) {
        this.smsCodeError = "Código inválido";
        this.codeTimeout = 0;
        return;
      }
      this.loading = true;
      const code = this.smsCode.replace(/\D/g, "");
      const credential = firebase.auth.PhoneAuthProvider.credential(
        window.confirmationResult.verificationId,
        code,
      );

      auth
        .signInWithCredential(credential)
        .then((result) => {
          this.$store.commit("session", result.user);
          setTimeout(() => {
            this.loading = false;
            this.$router.replace({ name: "dashboard" });
          }, 250);
        })
        .catch(() => {
          this.loading = false;
          this.smsCodeError = "Código inválido";
          this.codeTimeout = 0;
        });
    },

    signInWithPhoneNumber() {
      this.sendBtn = false;
      this.loading = true;
      const phoneNumber = `+${this.country}${this.phone.replace(/\D/g, "")}`;
      auth
        .signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          this.$messages.error({ text: getMessageError(error) });
          this.recaptchaSolved = false;
        })
        .finally(() => {
          this.loading = false;
          this.startCodeTimeout();
        });
    },

    resendCode() {
      this.codeTimeout = -1;
      clearInterval(this.intervalID);
      this.signInWithPhoneNumber();
    },

    startCodeTimeout() {
      this.codeTimeout = 30;
      this.intervalID = setInterval(() => {
        if (this.codeTimeout == 1) {
          clearInterval(this.intervalID);
        }
        this.codeTimeout--;
      }, 1000);
    },
  },
};
</script>

<style scoped >
.card-btn-bottom {
  position: absolute !important;
  bottom: 4px;
  right: 4px;
}
.card-btn-bottom-start {
  position: absolute !important;
  bottom: 8px;
  left: 4px;
}
</style>
